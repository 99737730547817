import { ApiFile, Company } from './common';
import { Address } from './address';
import { FileUploadRequest } from './files';

export enum ProfileSlug {
  COLLABORATOR = 'collaborator',
  ADMINISTRATOR = 'administrator',
  MANAGER = 'gerente',
}

export interface User {
  id: number;
  name: string;
  fullName?: string;
  email: string;
  occupation: string;
  username?: string;
  password?: string;
  cpf: string;
  rg: string;
  birth_date: string;
  telephone: string;
  gender: string;
  profile: Profile;
  profile_id?: number;
  company_id?: number;
  address?: Address;
  file?: ApiFile;
  picture?: ApiFile;
  company?: Company;
}

export interface Profile {
  id: number;
  name: string;
  color: string;
  slug: string;
  is_admin: boolean;
}

export interface Login {
  grant_type: string;
  username: string;
  password: string;
  company: number;
}

export interface Token {
  name: string;
  access_token: string;
  token_type: string;
  expire: string;
  user: User;
}

export interface CurrentUserFilter {
  profile?: boolean;
  address?: boolean;
  picture?: boolean;
}

export type UserInPaginate = User;

export interface Observation {
  id: number;
  observation: string;
  created_at: string;
  user?: User;
}

export interface ObservationSave {
  id: number;
  observation: string;
  task_id?: number;
}

export interface RegisterAllUsers extends FileUploadRequest {
  user: unknown;
  address?: unknown;
}

export interface UserDetail {
  name: string;
  email: string;
  occupation: string;
  cpf: string;
  rg: string;
  birth_date: string;
  telephone: string;
  gender: string;
  profile_id: number;
  profile_name: string;
  username: string;
  address?: Address;
  file?: ApiFile;
}

export interface UserAutocomplete {
  id: number;
  name: string;
  company: string;
  company_id: string;
  email: string;
  telephone: string;
  occupation: string;
  birth_date: string;
  profile: string;
  color?: string;
  menu?: boolean;
}
