import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { Address } from '../models/address';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { FilesUpload } from '../models/files';
import { ApiFile } from '../models/common';
import slugify from 'slugify';

export function toFormData<T extends object>(formValue: T): FormData {
  const formData = new FormData();

  if ('files' in formValue) {
    const files: File[] = Array.isArray(formValue['files']) ? formValue['files'] : [];
    const fileNames: string[] = [];

    files.forEach(file => {
      if (file instanceof File) {
        fileNames.push(file.name);
        formData.append('files', file, file.name);
      }
    });

    if (fileNames.length) {
      formData.append('files_names', JSON.stringify(fileNames));
    }

    delete formValue['files'];
  }

  Object.entries(formValue).forEach(([key, value]) => {
    const currentValue = typeof value === 'string' ? value : JSON.stringify(value);
    formData.append(key, currentValue);
  });

  return formData;
}

export function parse_date(str: string): Date {
  const [date, month, year] = str.split('/').map(n => +n);
  return new Date(year, month - 1, date);
}

export class SubscriptionSink {
  private subscription: Subscription[] = [];

  get sinks(): Subscription[] {
    return this.subscription;
  }

  set sink(value: Subscription) {
    if (value) {
      this.subscription.push(value);
    }
  }

  unsubscribe() {
    for (let i = 0; i < this.subscription.length; i++) {
      const subscriptionElement = this.subscription[i];
      try {
        subscriptionElement.unsubscribe();
      } catch {
        /* empty */
      }
    }
  }
}

export function markAsTouched(formObject: UntypedFormGroup | UntypedFormArray | FormArray | FormGroup) {
  Object.entries(formObject.controls).forEach(([name, control]) => {
    if (control instanceof UntypedFormControl || control instanceof FormControl) {
      if (control.errors != null) {
        console.debug(`Marking ${name} as touched to highlight errors`, control.errors);

        markFormControlAsTouched(control);
      }
    } else if (control instanceof UntypedFormArray || control instanceof FormArray) {
      markAsTouched(control);
    } else if (control instanceof UntypedFormGroup || control instanceof FormGroup) {
      markAsTouched(control);
    }
  });
}

export function markFormControlAsTouched(control: UntypedFormControl | FormControl) {
  control.markAsTouched();
  control.markAsDirty();
  control.updateValueAndValidity();
}

export function tryParse<T>(toParse: string | null, defaultReturn: T | null = null): T {
  if (!toParse) {
    return defaultReturn as T;
  }

  try {
    return JSON.parse(toParse) as T;
  } catch {
    return defaultReturn as T;
  }
}

export function tryStringifyNull(toParse: string): string | null {
  try {
    return JSON.stringify(toParse);
  } catch {
    return null;
  }
}

export function toNumberFixed(num: number | string | undefined | null) {
  if (num == null) {
    return num;
  }

  if (typeof num === 'string') {
    num = parseFloat(num);
  }

  return num.toPrecision(1).replace(',', '#').replace('.', ',').replace('#', '');
}

type ErrorDetailWithLoc = {
  error?: {
    detail?: {
      loc: string[];
      msg: string;
    }[];
  };
};
type ErrorDetail = {
  error?: {
    detail?: string;
  };
};
type ErrorsList = { error?: { errors?: string[] } };

type PossibleErrors = ErrorsList | ErrorDetail | ErrorDetailWithLoc | Error | string;

export function handleException(err: PossibleErrors, fallbackMessage = 'Ocorreu um erro inesperado!'): string {
  try {
    if (Array.isArray((err as ErrorsList)?.error?.errors)) {
      return (err as ErrorsList)!.error!.errors!.join(', ');
    } else if (Array.isArray((err as ErrorDetailWithLoc)?.error?.detail)) {
      const finalMsg = (err as ErrorDetailWithLoc)!.error!.detail!.map(
        msg => `${msg.loc[msg.loc.length - 1]}: ${msg.msg}`
      );

      return finalMsg.join('\n');
    } else if ((err as ErrorDetail)?.error?.detail) {
      return (err as ErrorDetail)!.error!.detail!;
    } else if (typeof err === 'string') {
      return err;
    } else {
      return fallbackMessage;
    }
  } catch (e) {
    return fallbackMessage;
  }
}

export function capitalize(str: string) {
  if (!str) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function mountAddress(data: Address) {
  if (!data || !Object.keys(data).length) {
    return '';
  }

  return `${data.street}, ${data.number} - ${data.city!.name}/${data.city!.state!.name}`;
}

export const Swal2 = Swal.mixin({
  heightAuto: false,
  cancelButtonColor: '#E74C3C',
});

export function toFilesUpload(files: ApiFile[]) {
  return files.map(file => {
    return {
      name: file.name,
      file: {
        name: file.filename,
      },
      id: file.id,
      url: `${environment.apiUrl}/file?file_id=${file.id}`,
    } as FilesUpload;
  });
}

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export function generateUsername(value: string): string | null {
  if (!value) {
    return null;
  }

  const nameCleanedSplit = value?.replace(' de ', ' ').split(' ') || [];
  const usernameBase =
    nameCleanedSplit.length > 1 ? `${nameCleanedSplit[0]} ${nameCleanedSplit.slice(-1)[0]}` : nameCleanedSplit[0] || '';

  return slugify(usernameBase, {
    replacement: '.',
    remove: /[0-9\\/,.-]/g,
    lower: true,
    trim: true,
  })
    .substring(0, 25)
    .trim();
}
